/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body')
            // $cookieBanner = $('.cookie-banner'),
            // $cookieClose = $cookieBanner.find('.item-close'),
            // $cookieAccept = $cookieBanner.find('.item-accept'),
            // $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;

        /*
        |
        | Cookie Manager
        |-----------------
        */
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     }
        // });

        $body.on('loaderEnd');

        /*
        |
        | Menu search
        |-----------------
        */

        $('.js-inputsearchheader').on('keyup', (e) => {
            if (e.keyCode == 13) {
                const origin = window.location.origin;
                const search_term = encodeURI($('.js-inputsearchheader').val());
                const url_params = `search=${search_term}`;

                console.log(origin + '/moteur-recherche/?' + url_params)

                window.location.replace(origin + '/moteur-recherche/?' + url_params);
            }
        })

        /*
        |
        | Slider home
        |-----------------
        */
        var swiperhome = new Swiper($('.swiper-home').find('.swiper-container'), {
            speed: 1000,
            spaceBetween: 0,
            paginationClickable: true,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 1
        });

        /*
        |
        | Slider home metier
        |-----------------
        */
        var swiperhome = new Swiper($('.swiper-home-metier').find('.swiper-container'), {
            speed: 1000,
            paginationClickable: true,
            centeredSlides: true,
            spaceBetween: 0,
            slidesPerView: 1.2,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            loopedSlides: 50,
            pagination: {
                el: '.swiper-pagination-metier',
                clickable: true
            },
            breakpoints: {
                765: {
                    slidesPerView: 1
                }
            }
        });

        /*
        |
        | Tabs
        |-----------------
        */

        $('.tabs-nav li.firsttab').addClass('tab-active'); /* Met en vert le premier onglet */

        $('.tabs-stage div').hide(); /* Cache tous les contenus */
        $('.tabs-stage .firstcont').show(); /* Affiche le premier contenu */

        $('.tabs-nav a').on('click', function (event) {
            event.preventDefault();
            $(this).parent().parent().parent().find('li').removeClass('tab-active'); /* Retir le vert de tous les onglets */
            $(this).parent().parent().addClass('tab-active'); /* Ajoute le vert sur l'onglet selectionné */

            $(this).parent().parent().parent().parent().find('.tabs-stage div').hide(); /* Masque tous les contenus */
            $($(this).attr('href')).show(); /* Affiche le contenu correspondant à l'onglé selectionné */
        });

        /*
        |
        | Menu
        |-----------------
        */
        jQuery(document).ready(function () {
            jQuery(".menu").on('click', function (e) {
                const isActive = jQuery(this).find(".item-link").hasClass('active');
        
                /* Réinitialiser les autres éléments */
                jQuery('.nav-drop').slideUp("400").addClass('nav-drop-hide');
                jQuery('.item-menu').find(".item-link").removeClass('active');
                jQuery('#header').removeClass('ssmenu');
        
                /* Si l'élément cliqué est déjà actif, ne pas le rouvrir */
                if (!isActive) {
                    jQuery(this).find(".item-link").addClass('active');
                    jQuery(this).find(".nav-drop").slideDown("400").removeClass('nav-drop-hide');
                    jQuery('#header').addClass('ssmenu'); 
                }
            });
        
            /* Fermer le sous-menu lorsque la fenêtre est redimensionnée */
            jQuery(window).on('resize', function () {
                jQuery('.nav-drop').slideUp("400").addClass('nav-drop-hide');
                jQuery('.item-menu').find(".item-link").removeClass('active');
                jQuery('#header').removeClass('ssmenu');
            });
        });
        
        


        /*
        |
        | Réduir le menu au scroll
        |-------------------------
        */
        function minimenu() {
            if ($(window).scrollTop() > 50) {
                $('#header').addClass('mini-menu');
            } else {
                $('#header').removeClass('mini-menu');
            }
        }

        minimenu();
        $(window).scroll(function () {
            minimenu();
        });


        jQuery(function () {
            var selectedClass = "";
            $(".fil-cat").click(function () {

                $(".fil-cat").removeClass('active');
                $(this).addClass('active');
                selectedClass = $(this).attr("data-rel");

                $("#portfolio").fadeTo(100, 0.1);
                $("#portfolio .tile").not("." + selectedClass).fadeOut().removeClass('scale-anm');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('scale-anm');
                    $("#portfolio").fadeTo(300, 1);
                }, 300);
            });
        });


        /* filter */
        jQuery('.js-filter').on('click', function () {
            var $color = jQuery(this).attr('data-color');
            if ($color == 'all') {
                jQuery('.js-filterable').removeClass('is-hidden');

                jQuery('.js-filter').removeClass('active');
                jQuery('.all').addClass('active');
            } else {
                jQuery('.js-filterable').addClass('is-hidden');
                jQuery('.js-filterable[data-color=' + $color + ']').removeClass('is-hidden');

                jQuery('.js-filter').removeClass('active');
                jQuery('.' + $color).addClass('active');
            }
        });

        /* filter */
        jQuery('.btn-menu').on('click', function () {
            jQuery('body').toggleClass('overhidden');
        });


        if (jQuery('.item-pf').length >= 6) {
            jQuery('.loadMoreBtn').removeClass('is-hidden');
        } else {
            jQuery('.loadMoreBtn').addClass('is-hidden');
        }
        if (jQuery('.item-pf2').length >= 3) {
            jQuery('.loadMoreBtn').removeClass('is-hidden');
        } else {
            jQuery('.loadMoreBtn').addClass('is-hidden');
        }


        jQuery('.toggle1').click(function (e) {
            e.preventDefault();

            jQuery('.inner1').slideToggle(350);
            jQuery('.inner2').slideUp(350);

            // if (jQuery('.inner2').hasClass('show')) {
            //     jQuery('.inner2').removeClass('show');
            //     jQuery('.inner2').slideUp(350);
            // } else {
            //     $this.parent().parent().find('.inner').removeClass('show');
            //     $this.parent().parent().find('.inner').slideUp(350);
            //     $this.next().toggleClass('show');
            //     $this.next().slideToggle(350);
            // }
        });

        jQuery('.toggle2').click(function (e) {
            e.preventDefault();
            jQuery('.inner2').slideToggle(350);
            jQuery('.inner1').slideUp(350);
        });

    }
}
