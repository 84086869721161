/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/dist/js/swiper.min';
import SplitText from "@lib/gsap-pro/SplitText";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import Scrollbar from 'smooth-scrollbar';
import * as PIXI from 'pixi.js';

/*
|
| Importing Components
|-----------------------
*/
// import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import PixiRipple from './components/pixi-ripple.js';

/*
|
| Importing Utils
|-------------------
*/
import '@utils/fa';
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import recherche from '@pages/recherche.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, ScrollMagic, SplitText, PIXI, PixiRipple]
    },
	{
		'file': main, 
		'dependencies': [app, Swiper]
    },
	{
		'file': sample, 
		'dependencies': [app],
		'resolve': '#page-sample'
    },
    {
        'file': contact,
        'dependencies': [app, customGoogleMap],
        'resolve': '#page-contact'
    },
    {
        'file': recherche,
        'dependencies': [app, customGoogleMap],
        'resolve': '#page-recherche'
    }
]);


/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

