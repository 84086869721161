/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Menu, Kira, ScrollMagic, SplitText, PIXI, PixiRipple) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body        = $('body'),
            $pageLoader  = $('.page-loader'),
            scrollAreas  = document.querySelectorAll('.scroll-area'),
            $menuWrapper = $('#mobile-menu'),
            $menuButton  = $('#header .btn-menu'),
            $menuBars    = $menuButton.find('.item-burger > span'),
            $menuClose   = $menuWrapper.find('.item-close'),
            $menuLi      = $menuWrapper.find('ul li')
		;
		

		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
		*/
        const loaderTl = gsap.timeline({ paused: true, onComplete: () => $pageLoader.remove() });

        loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: "power0.easeNone" }, 'start')
                .to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: "power1.easeOut" }, '-=0')
                .call(() => { app.dispatchEvent($body, 'loaderEnd'); }) // Remplace addCallback
                .to($pageLoader, 0.8, { autoAlpha: 0, ease: "power1.easeOut" }, '-=0');

        $(window).on('load', function () {
            loaderTl.play();
        });


        /*
		|
		| Menu
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: "power1.easeOut" }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: "power1.easeOut" }, 'start')
			.from($menuWrapper, 0.5, { autoAlpha: 0, ease: "power1.easeOut" }, 'start+=0.3')
            .from($menuLi, 0.6, { autoAlpha: 0, x: 20, ease: "power1.easeOut", stagger: 0.1 }, '+=0');
        
        menu.init();

        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loaderEnd'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 30, autoAlpha: 0, ease: "power1.easeOut" }, start);
        });
        
        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: "sine.easeOut" }, start);
        });

        /*
		| fadeInUp.parallax
		|--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, { y: 80, autoAlpha: 0, ease: "power1.easeOut" }, start);
        });

        /*
		| fadeInLeft.stagger.sm
		|-----------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.from($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, x: 20, ease: "power1.easeOut", stagger: 0.1 }, start);
        });

        /*
		| scaleX
		|--------
        */
        kira.add('scaleX', ($item, timeline, start) => {
            timeline.from($item, 1, { scaleX: 0, transformOrigin: 'left top', ease: "expo.easeInOut" }, start);
        });

        /*
		| splitline
		|------------
        */
        kira.add('splitline', ($item, timeline, start) => {
            new SplitText($item, { type: 'lines, words', linesClass: 'item-line' });

            $.each($item.find('.item-line'), function(key, value) {
                const s = key === 0 ? start : '+=0.05';
                timeline.to($(this).find('> div, > span'), 0.1, { className: '+=animated' }, s);
            });
        });

        kira.init();
	}
};

