/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        console.log('recherche.js')

        const search = window.location.href.split('=').pop();
        console.log(search)

        $('.js-title').each(function (index, el) {
            var regex = new RegExp(`${search}`, "gi");
            $(el).html(function(index,html){
                return html.replace(regex,`<span class="orange">$&</span>`);
            });
        })

        $('.js-text').each(function (index, el) {
            var regex = new RegExp(`${search}`, "gi");
            $(el).html(function(index,html){
                return html.replace(regex,`<span class="orange">$&</span>`);
            });
        })
    }
}
